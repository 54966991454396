@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src:
    // url('../fonts/Inter-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    // url('../fonts/Inter-Regular.woff') format('woff'),   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('../fonts/Inter-Regular.ttf') format('opentype'),   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: 
    // url('../fonts/Inter-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    // url('../fonts/Inter-Bold.woff') format('woff'),   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    url('../fonts/Inter-Bold.ttf') format('opentype'),   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
