@import '../../scss/variables.scss';

.return-btn {
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: 12px 5px;
  color: $text-color;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;

  &__icon {
    margin-right: 12px;
    height: 10px;
    transform: rotate(180deg);
    fill: $text-light-color;
  }
}