@import './variables.scss';

.form-group {
  margin-bottom: 20px;
  position: relative;

  &__error {
    position: absolute;
    font-size: 11px;
    font-weight: 400;
    color: $red;
    bottom: -15px;
  }
}

.label {
  font-weight: bold;
  font-size: 12px;
  color: $text-color;
  line-height: 2;

  &--required {
    &::after{
      content: '*';
      color: $red;
    }
  }
  &--optional {
    &::after{
      content: '(optional)';
      color: $text-light-color;
      font-weight: 400;
      margin-left: 2px;
    }
  }

}

.textarea {
  resize: none;
}

.input {
  display: block;
  width: 100%;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 2px;
  transition: all 0.15s ease-in-out;
  outline: none;

  &:focus {
    border-color: #3182ce;
    box-shadow: #3082cf 0px 0px 0px 1px;
  }

  &--error {
    border: 1px solid $red;
    color: $red;
  }

  &:disabled::placeholder {
    color: transparent;
  }

  &::placeholder {
    color: $text-color;
  }
}