.spinner{
  display: inline-block;
  border: 2px solid #d8b9c3;
  border-right-color: transparent !important;
  border-radius: 50%;
  animation: spinner .75s linear infinite;
  // position: absolute;
}

@keyframes spinner{
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg);
  }
}
