@import '../../scss/variables.scss';

.server-error-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 14px 0 45px;

  &__image {
    margin-top: 20px;
    width: 65vw;
    max-width: 413px;
  }

  &__title {
    margin: 50px 0;
    color: $black;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0.005em;
  }

  &__text {
    max-width: 770px;
    margin: 0;
    padding: 0 20px;
    color: $text-color;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.005em;
  }
}