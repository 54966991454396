.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  animation: fadeInModal 0.5s;

  &--close {
    animation: fadeOutModal 0.5s;
  }

  &__item {
    animation: fadeInUp 0.5s;

    &--close {
      animation: fadeOutDown 0.5s;
    }
  }

  @keyframes fadeInModal {
    0% {
      background: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0);
    }

    100% {
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(5px);
    }
  }

  @keyframes fadeOutModal {
    0% {
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(5px);
    }

    100% {
      background: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0);
    }
  }
}
