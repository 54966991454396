@import "../../scss/variables.scss";

.auth-page {
  padding: 56px 20px;
  background-color: #fff;
  min-height: 100vh;

  &__container {
    max-width: 100%;
    margin: auto;
  }

  &__logo-wrap {
    font-size: 24px;
    color: #b5b5b5;
    font-weight: bold;
  }

  &__title {
    font-size: 24px;
    margin: 30px 0 18px;
    color: $black;
  }

  &__text {
    font-size: 16px;
    color: $text-color;
    line-height: 1.35;
    height: 106px;
    overflow-y: hidden;

    &--auto-height {
      height: auto;
    }
  }

  &__btns {
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__footer {
    font-size: 14px;
    color: $text-color;
    text-align: center;
    margin-top: 90px;
  }
}

@media (min-width: 1024px) {
  .auth-page {
    &__container {
      max-width: 370px;
    }
    &__header {
      text-align: center;
    }
  }
}
